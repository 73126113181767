<!-- Alert messages -->
<div *ngIf="submitStatus" class="modal-success">Success!</div>

<div id="triggerSurveyModal" class="modal">
  <div class="modal-content">
    <div>
      <span
        class="close"
        (click)="
          closeModal('triggerSurveyModal', [
            'survey-dropdown',
            'patient-id-trigger_survey',
            'day-offset-trigger_survey',
            'device-serial-trigger_survey'
          ])
        "
        >&times;</span
      >
      <h1>Trigger Survey</h1>
    </div>

    <div class="survey-fields">
      <div class="select">
        <p><strong>Select Survey</strong></p>
        <select id="survey-dropdown" class="site-nav__dropdown">
          <option value="">Select a survey</option>
          <option value="subjective-feedback-v1">
            Onboarding (subjective-feedback-v1)
          </option>
          <option value="sleep-concierge-v1">
            Sleepiness (sleep-concierge-v1)
          </option>
          <option value="sleep-quality-v1">
            Sleep Quality (sleep-quality-v1)
          </option>
          <option value="mask-confirmation-v1">
            Mask Confirmation (mask-confirmation-v1)
          </option>
          <option value="mask-sense-v1">Mask Sense (mask-sense-v1)</option>
        </select>
        <div id="surveyError" class="modal-error" [class.show]="missingSurvey">
          Please select a survey
        </div>
      </div>

      <div class="patient-id">
        <p><strong>Patient ID</strong></p>
        <input
          type="text"
          id="patient-id-trigger_survey"
          class="site-nav__textbox"
          [(ngModel)]="generatedPatientIDTrigger"
        />
      </div>

      <div
        *ngIf="selectedSurveyId !== 'subjective-feedback-v1'"
        class="day-offset"
      >
        <p><strong>Day Offset</strong></p>
        <input
          type="text"
          id="day-offset-trigger_survey"
          class="site-nav__textbox"
          [(ngModel)]="dayOffset"
          placeholder="Day Offset"
        />
      </div>

      <div
        *ngIf="selectedSurveyId !== 'subjective-feedback-v1'"
        class="device-serial"
      >
        <p><strong>Device Serial</strong></p>
        <input
          type="text"
          id="device-serial-trigger_survey"
          class="site-nav__textbox"
          [(ngModel)]="deviceSerial"
          placeholder="Device Serial"
        />
      </div>

      <div *ngIf="selectedSurveyId === 'subjective-feedback-v1'" class="select">
        <p><strong>Device Type</strong></p>
        <select id="device-type-trigger" class="site-nav__dropdown">
          <option value="AS11" selected>AirSense 11</option>
          <option value="AC11">AirCurve 11</option>
        </select>
      </div>

      <div *ngIf="selectedSurveyId === 'subjective-feedback-v1'" class="select">
        <p><strong>Select Country</strong></p>
        <select id="country-dropdown-trigger" class="site-nav__dropdown">
          <option value="">Select a country</option>
          <option *ngFor="let country of countries" [ngValue]="country.code">
            {{ country.name }} ({{ country.code }})
          </option>
        </select>
        <div
          id="countryError"
          class="modal-error"
          [class.show]="missingCountry"
        >
          Please select a country
        </div>
      </div>

      <div *ngIf="selectedSurveyId === 'subjective-feedback-v1'" class="select">
        <p><strong>Select Language</strong></p>
        <select id="language-dropdown-trigger" class="site-nav__dropdown">
          <option value="">Select a language</option>
          <option
            *ngFor="let language of languages"
            [ngValue]="language.locale"
          >
            {{ language.language }} ({{ language.locale }})
          </option>
        </select>
        <div
          id="languageError"
          class="modal-error"
          [class.show]="missingLocale"
        >
          Please select a locale
        </div>
      </div>

      <button class="submit-btn" (click)="triggerSurvey()">
        <strong>Submit</strong>
      </button>
      <div *ngIf="triggerError" class="modal-submit-error">
        Error, Check Logs
      </div>
    </div>
  </div>
</div>
