import {
  enrollOnboardingPayload,
  triggerSurveyPayload,
} from '../../interfaces/trigger_survey_interfaces';
import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { environment } from 'src/app/environment/environment';
import { SharedService } from '../../services/shared.service';
import { switchboardCall } from 'src/app/utils/common_functions';
import {
  openModal,
  closeModal,
  checkEmptyInputs,
} from 'src/app/utils/common_modal_functions';
import { countries, languages, surveys } from 'src/app/utils/common_lists';
import { errorTimeout } from 'src/app/utils/common_vars';

declare var $: any;
@Component({
  selector: 'app-trigger-survey',
  templateUrl: './trigger-survey.component.html',
  styleUrl: './trigger-survey.component.scss',
})
export class TriggerSurveyComponent {
  selectedSurveyId: string = '';
  //Default to AS11 as device
  selectedDevice: string = 'AS11';
  selectedLocale: string = '';
  selectedCountry: string = '';

  generatedPatientIDTrigger: string = '';
  missingSurvey: boolean = false;
  dayOffset: string = '';
  lastDayOffsetTrg: string = '';
  deviceSerial: string = '';
  triggerError: boolean = false;
  triggerFieldError: boolean = false;
  submitStatus: boolean = false;

  //Values for patient schedule trigger open modal
  PselectedSurveyId: string = '';
  PpatientId: string = '';
  PdayOffset: string = '';
  PdeviceSerial: string = '';

  surveys = surveys;
  countries = countries;
  languages = languages;

  missingCountry: boolean = false;
  missingLocale: boolean = false;

  surveyActions: { [key: string]: string } = {
    'subjective-feedback-v1': 'onboarding',
    'sleep-concierge-v1': 'trigger',
    'sleep-quality-v1': 'trigger',
    'mask-confirmation': 'trigger',
    'mask-sense-v1': 'trigger',
  };

  constructor(
    private elementRef: ElementRef,
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef,
    private platformLocation: PlatformLocation,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sharedService.triggerOpen$.subscribe(() => {
      this.handleTriggerOpen();
    });

    this.sharedService.scheduleOpenTrigger$.subscribe((data) => {
      this.PselectedSurveyId = data.selectedSurveyId;
      this.PpatientId = data.patientId;
      this.PdayOffset = data.dayOffset;
      if (data.deviceSerial) {
        this.PdeviceSerial = data.deviceSerial;
      } else {
        this.PdeviceSerial = '';
      }

      this.handleScheduleTriggerOpen();
    });
  }

  ngAfterViewInit(): void {
    $('#survey-dropdown').select2({
      width: '100%',
      placeholder: 'Select an option',
    });

    $('#survey-dropdown').on('change', (e: any) => {
      this.selectedSurveyId = $('#survey-dropdown').val();
      this.reloadFields();
    });

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if ($.fn.select2 && $('#survey-dropdown').data('select2')) {
      $('#survey-dropdown').select2('destroy');
    }

    if ($('#device-type-trigger').data('select2')) {
      $('#device-type-trigger').select2('destroy');
    }

    if ($('#country-dropdown-trigger').data('select2')) {
      $('#country-dropdown-trigger').select2('destroy');
    }

    if ($('#language-dropdown-trigger').data('select2')) {
      $('#language-dropdown-trigger').select2('destroy');
    }
    this.cdr.detectChanges();
  }

  handleTriggerOpen(): void {
    openModal(
      'triggerSurveyModal',
      ['patient-id-trigger_survey'],
      this.elementRef
    );
  }

  handleScheduleTriggerOpen() {
    const modal = this.elementRef.nativeElement.querySelector(
      `#triggerSurveyModal`
    ) as HTMLDivElement;

    const patient = this.elementRef.nativeElement.querySelector(
      '#patient-id-trigger_survey'
    ) as HTMLInputElement;

    const dayOffsetIn = this.elementRef.nativeElement.querySelector(
      '#day-offset-trigger_survey'
    ) as HTMLInputElement;

    const deviceSerialIn = this.elementRef.nativeElement.querySelector(
      '#device-serial-trigger_survey'
    ) as HTMLInputElement;

    patient.value = this.PpatientId;
    dayOffsetIn.value = this.PdayOffset;
    deviceSerialIn.value = this.PdeviceSerial;

    $('#survey-dropdown').val(this.PselectedSurveyId).trigger('change');
    this.cdr.detectChanges();
    this.selectedSurveyId = this.PselectedSurveyId;

    modal.style.display = 'block';
  }

  validateSelection() {
    this.missingSurvey = !this.selectedSurveyId;
  }

  triggerSurvey(): void {
    this.triggerError = false;
    this.triggerFieldError = false;
    const patientIdIn = this.elementRef.nativeElement.querySelector(
      '#patient-id-trigger_survey'
    ) as HTMLInputElement;
    const deviceSerialIn = this.elementRef.nativeElement.querySelector(
      '#device-serial-trigger_survey'
    ) as HTMLInputElement;
    const dayOffset = this.elementRef.nativeElement.querySelector(
      '#day-offset-trigger_survey'
    ) as HTMLInputElement;

    if (!$('#survey-dropdown')?.val()) {
      this.triggerFieldError = true;
      this.missingSurvey = true;
      this.cdr.detectChanges();
      console.log(this.missingSurvey);
      setTimeout(() => {
        this.missingSurvey = false;
        this.cdr.detectChanges();
      }, errorTimeout);
    }

    checkEmptyInputs(
      [
        'day-offset-trigger_survey',
        'last-day-offset-trigger_survey',
        'device-serial-trigger_survey',
      ],
      this.triggerFieldError
    );

    if (this.triggerFieldError) {
      console.log('Missing fields');
      this.triggerFieldError = false;
      return;
    }

    let lastDayOffset: number | undefined;
    if (dayOffset?.value) {
      const dayOffsetValue = Number(dayOffset.value);

      if (dayOffsetValue === 4 || dayOffsetValue === 7) {
        lastDayOffset = 3;
      } else if (dayOffsetValue % 7 === 1) {
        lastDayOffset = dayOffsetValue - 1;
      } else {
        lastDayOffset = Math.max(0, dayOffsetValue - 7);
      }
    }

    let payload: any;

    if (this.selectedSurveyId === 'subjective-feedback-v1') {
      payload = {
        patientId: patientIdIn.value,
        type: 'onboarding',
        isoCountryCode: this.selectedCountry,
        locale: this.selectedLocale,
        flowgenType: this.selectedDevice,
      } as enrollOnboardingPayload;
    } else {
      payload = {
        patient_id: patientIdIn.value,
        day_offset: Number(dayOffset.value),
        last_day_offset: lastDayOffset,
        scheduler_type: 'SURVEY_TRIGGER',
        survey_id: this.selectedSurveyId,
        ...(deviceSerialIn.value &&
          deviceSerialIn.value !== '' && {
            device_serial: deviceSerialIn.value,
            source_id: `DEVICE_SERIAL#${deviceSerialIn.value}`,
          }),
      } as triggerSurveyPayload;
      if (!deviceSerialIn.value || deviceSerialIn.value === '') {
        payload.source_id = `PATIENT_ID#${patientIdIn.value}`;
      }
    }

    console.log(
      'Executing trigger survey switchboard call with payload: ',
      payload
    );
    this.executeTrigger(payload);
  }

  async executeTrigger(
    payload: triggerSurveyPayload | enrollOnboardingPayload
  ): Promise<void> {
    try {
      await switchboardCall(
        {
          data: payload,
          action: this.surveyActions[this.selectedSurveyId],
          ...(this.selectedSurveyId !== 'subjective-feedback-v1' && {
            scheduler_type: 'SURVEY_TRIGGER',
          }),
        },
        environment.switchboardUri,
        this.platformLocation,
        this.httpClient
      );
      console.log('Trigger Survey successful');
      closeModal(
        'triggerSurveyModal',
        [
          'patient-id-trigger_survey',
          'day-offset-trigger_survey',
          'device-serial-trigger_survey',
        ],
        this.elementRef
      );
      this.submitStatus = true;
      this.sharedService.setSuccessfulTrigger(true);
      this.cdr.detectChanges();
      setTimeout(() => {
        this.submitStatus = false;
        this.cdr.detectChanges();
      }, errorTimeout);
    } catch (error) {
      console.error(error);
      this.triggerSurveyError();
    }
  }

  triggerSurveyError(): void {
    this.triggerError = true;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.triggerError = false;
      this.cdr.detectChanges();
    }, errorTimeout);
  }

  closeModal(id: string, drpdownIds: string[]) {
    closeModal(id, drpdownIds, this.elementRef);
  }

  reloadFields() {
    this.cdr.detectChanges();
    if (this.selectedSurveyId === 'subjective-feedback-v1') {
      this.initializeAdditionalDropdowns();
    }
  }

  initializeAdditionalDropdowns() {
    // If subjective-feedback is picked as a survey, initialized required fields
    $('#device-type-trigger').select2({
      width: '100%',
    });

    $('#device-type-trigger').on('change', (e: any) => {
      this.selectedDevice = $('#device-type-trigger').val();
    });

    $('#country-dropdown-trigger').select2({
      width: '100%',
      placeholder: 'Select an option',
    });

    $('#country-dropdown-trigger').on('change', (e: any) => {
      this.selectedCountry = $('#country-dropdown-trigger')
        .val()
        .match(/\(([^)]+)\)/)?.[1];
    });

    $('#language-dropdown-trigger').select2({
      width: '100%',
      placeholder: 'Select an option',
    });

    $('#language-dropdown-trigger').on('change', (e: any) => {
      this.selectedLocale = $('#language-dropdown-trigger')
        .val()
        .match(/\(([^)]+)\)/)?.[1];
    });
  }
}
